import { Suspense, lazy } from "react"
import Loader from "./components/Loader";
const Layout = lazy(() => import('./pages/Layout'));

const App = () => {
  return (
    <>
      <Suspense fallback={<Loader />}>
        <Layout />
      </Suspense>
    </>
  )
}

export default App
