import logo from '../assets/images/logo.png';

const Loader = () => {
    return (
        <>
            <div className='loader_div'>
                <img src={logo} alt='' />
            </div>
        </>
    )
}

export default Loader;
